.review-table {
  padding: 30px 40px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  min-width: 1200px;
  overflow-x: auto;
  margin: 20px 0;
}

.review-table .ant-table-thead > tr > th {
  background: #f5f5f5;
  font-weight: 600;
  padding: 20px 24px;
}

.review-table .ant-table-tbody > tr > td {
  padding: 20px 24px;
  vertical-align: top;
  white-space: normal;
  word-wrap: break-word;
  line-height: 1.6;
}

.review-table .ant-table-tbody > tr:hover {
  background: #fafafa;
}

/* Add more spacing between rows */
.review-table .ant-table-tbody > tr {
  border-bottom: 1px solid #f0f0f0;
}

/* 设置表格单元格的最小和最大宽度 */
.review-table .ant-table-cell {
  min-width: 150px;
  max-width: 800px;
} 