:root {
  --primary-color: #0051c3;
  --secondary-color: #ff7a2f;
  --text-color: #333;
  --background-color: #fff;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  line-height: 1.6;
  color: var(--text-color);
}

.header {
  position: fixed;
  top: 0;
  width: 100%;
  background: url('../images/background.png') no-repeat center center;
  background-size: cover;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  z-index: 1000;
  padding: 1rem 0;
}

.header-container {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: transparent;
}

.logo a {
  text-decoration: none;
}

.logo h1 {
  color: white;
  font-size: 2rem;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.auth-buttons button {
  padding: 0.5rem 1rem;
  margin-left: 1rem;
  border-radius: 4px;
  cursor: pointer;
}

.btn-signin {
  background: none;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
}

.btn-join {
  background: var(--primary-color);
  border: none;
  color: white;
}

/* Common container width and padding */
.content-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

/* Subject Grid Styles */
.subject-grid {
  padding-top: 8rem;
  padding-bottom: 4rem;
}

.subject-grid-header {
  max-width: 1200px;
  margin: 0 auto;
  margin-bottom: 3rem;
  padding: 0 2rem;
  background: none;
  box-shadow: none;
  border-radius: 0;
}

.subject-grid-header h2 {
  text-align: left;
  font-size: 2rem;
  color: var(--text-color);
  margin: 0;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

.subject-card {
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  text-align: center;
  text-decoration: none;
  color: var(--text-color);
  transition: transform 0.2s, box-shadow 0.2s;
  display: block;
  background: white;
}

.subject-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
}

.subject-card .subject-icon {
  font-size: 2rem;
  margin-bottom: 1rem;
  display: block;
}

.subject-card h3 {
  margin: 1rem 0;
  color: var(--text-color);
  font-size: 1.5rem;
}

.subject-card p {
  color: #666;
  font-size: 1rem;
  line-height: 1.5;
}

/* Grade Levels Styles */
.grade-levels {
  background: #f5f7fa;
  padding: 4rem 0; /* Remove horizontal padding */
}

.grade-levels h2 {
  text-align: left;
  font-size: 2rem;
  color: var(--text-color);
  margin-bottom: 3rem;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 2rem; /* Add padding to heading */
}

.grades-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem; /* Add padding to container */
}

/* Grade Levels Styles */
.grade-module {
  margin-bottom: 1rem;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  overflow: hidden;
}

/* Color variations */
.indigo-bdr { border-color: #4B0082; }
.indigo-bg { background-color: #4B0082; }
.indigo-text { color: #4B0082; }
.indigo-fill-svg { fill: #4B0082; }

.coral-bdr { border-color: #FF7F50; }
.coral-bg { background-color: #FF7F50; }
.coral-text { color: #FF7F50; }
.coral-fill-svg { fill: #FF7F50; }

.teal-bdr { border-color: #008080; }
.teal-bg { background-color: #008080; }
.teal-text { color: #008080; }
.teal-fill-svg { fill: #008080; }

.violet-bdr { border-color: #8A2BE2; }
.violet-bg { background-color: #8A2BE2; }
.violet-text { color: #8A2BE2; }
.violet-fill-svg { fill: #8A2BE2; }

.crimson-bdr { border-color: #DC143C; }
.crimson-bg { background-color: #DC143C; }
.crimson-text { color: #DC143C; }
.crimson-fill-svg { fill: #DC143C; }

.emerald-bdr { border-color: #50C878; }
.emerald-bg { background-color: #50C878; }
.emerald-text { color: #50C878; }
.emerald-fill-svg { fill: #50C878; }

.amber-bdr { border-color: #FFBF00; }
.amber-bg { background-color: #FFBF00; }
.amber-text { color: #FFBF00; }
.amber-fill-svg { fill: #FFBF00; }

.grade-box-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem;
  background: #f8f9fa;
}

.grade-box-tab {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin-right: 1rem;
  color: white;
  font-weight: bold;
}

.grade-box-hdr {
  display: flex;
  align-items: center;
  margin: 0;
  flex-grow: 1;
}

.grade-box-name {
  font-size: 1.2rem;
  font-weight: bold;
}

.grade-box-long-name {
  display: none;
}

.grades-list-arrow {
  width: 9px;
  height: 17px;
  margin-left: auto;
}

.grade-body {
  padding: 1rem;
}

.grade-description {
  margin-bottom: 1rem;
  color: #666;
}

.list-subject-links {
  list-style: none;
  padding: 0;
  margin: 1rem 0;
}

.subject-link-item {
  margin-bottom: 0.5rem;
}

.subject-skill-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
}

.subject-hdr {
  margin: 0;
  font-size: 1rem;
  color: #333;
}

.skill-lk {
  text-decoration: none;
  color: #1a7ce4;
}

.skill-lk:hover {
  text-decoration: underline;
}

@media (min-width: 768px) {
  .grade-box-short-name {
    display: none;
  }
  
  .grade-box-long-name {
    display: block;
  }
}

@media (max-width: 1024px) {
  .grades-container {
    grid-template-columns: repeat(2, 1fr); /* 2 columns on tablets */
  }
}

@media (max-width: 768px) {
  .grid-container,
  .grades-container {
    grid-template-columns: 1fr;
  }
}

/* Subject Navigation Styles */
.subject-nav-container {
  border-bottom: 1px solid #e6e6e6;
  background: white;
  padding: 0.5rem 0;
  margin-top: 4.5rem; /* Reduce space below header */
}

.subject-nav {
  display: flex;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  gap: 0.25rem; /* Reduce gap between items */
}

.subject-nav-item {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  padding: 0.5rem 1rem; /* Make items more compact */
  text-decoration: none;
  color: #666;
  border-radius: 4px; /* Smaller border radius */
  transition: all 0.2s;
  margin-right: 0.25rem;
  font-size: 0.9rem; /* Slightly smaller font */
}

.subject-nav-item:hover {
  background-color: rgba(102, 102, 102, 0.05); /* Light grey with transparency */
  color: #666;
}

.subject-nav-item.active {
  background-color: rgba(102, 102, 102, 0.1); /* Slightly darker grey with transparency */
  color: #666;
}

.subject-icon {
  font-size: 1.1rem;
}

.subject-name {
  font-size: 0.95rem;
  font-weight: 500;
}

.subject-page {
  padding-top: 0; /* Remove extra padding */
}

.subject-content {
  flex: 1;
  padding: 20px;
  padding-top: 0;
  display: flex;
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

/* Grade Sidebar Styles */
.grade-sidebar {
  width: 80px;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding-top: 2rem;
  margin-left: -60px; /* Move left by the width of the circle */
}

.grade-link {
  text-decoration: none;
  display: block;
  padding-left: 20px; /* Add some padding to keep circles partially visible */
  transition: padding-left 0.2s; /* Smooth transition for hover effect */
}

.grade-link:hover {
  padding-left: 0; /* Show full circle on hover */
}

.grade-circle {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: var(--grade-color);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 1.4rem;
}

.content-area {
  flex: 1;
  min-height: 500px;
}

/* Grade List Styles */
.grade-list {
  flex: 1;
  padding: 2rem 0;
}

.grade-item {
  background: white;
  border-radius: 8px;
  margin-bottom: 1rem;
  padding: 1.5rem;
  box-shadow: 0 1px 3px rgba(0,0,0,0.05);
}

.grade-header {
  display: flex;
  align-items: flex-start;
  gap: 1.5rem;
}

.grade-icon {
  width: 45px;
  height: 45px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  font-size: 1.4rem;
  flex-shrink: 0;
}

.grade-info {
  flex: 1;
}

.grade-info h2 {
  margin: 0 0 0.5rem 0;
  font-size: 1.4rem;
  color: #333;
}

.grade-includes {
  color: #666;
  font-size: 0.9rem;
  line-height: 1.6;
}

.include-item:not(:last-child)::after {
  content: '|';
  margin: 0 0.5rem;
  color: #ccc;
}

.see-all-btn {
  background: #00a4e4;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.75rem 1.25rem;
  cursor: pointer;
  font-weight: 500;
  white-space: nowrap;
  transition: background-color 0.2s ease;
}

.see-all-btn:hover {
  background: #0090cb;
}

/* 添加数学页面的介绍样式 */
.subject-intro {
  margin-bottom: 2rem;
}

.subject-intro h1 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 1rem;
}

.subject-intro p {
  font-size: 1.1rem;
  color: #666;
  line-height: 1.6;
  max-width: 800px;
}

/* Grade Page Styles */
.grade-page {
  padding: 10px 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.grade-sections {
  display: grid;
  gap: 20px;
}

.grade-section {
  padding: 10px;
  background: #fff;
  border-radius: 8px;
}

.grade-section h2 {
  color: #333;
  margin-bottom: 1rem;
  font-size: 1.4rem;
}

.section-header {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.section-header h1 {
  font-size: 24px;
  margin: 0;
}

.section-header h2 {
  font-size: 24px;
  margin: 0;
  color: #333;
  font-weight: normal;
}

.ant-select-selector {
  background-color: transparent !important;
  border: 1px solid rgba(255, 255, 255, 0.5) !important;
  color: white !important;
}

.ant-select-selection-item {
  color: white !important;
}

.ant-select-arrow {
  color: white !important;
}

.subject-link {
  text-decoration: none;
  color: inherit;
  transition: opacity 0.2s;
}

.subject-link:hover {
  opacity: 0.8;
} 